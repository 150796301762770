import { BASE_URL } from "../../../utils/app";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  CaseControlMoistureTechnologyQuery,
  Order_By,
  useCaseControlMoistureTechnologyLazyQuery
} from "../../../generated/graphql";
import { CaseTypeSelect } from "../../../components/case-type-select";
import { FormattedDate, FormattedMessage } from "react-intl";
import { User, UserSelect } from "../../../components/user-select";
import { getCaseWhere } from "../../case/case-list";
import { useHistory } from "react-router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";

export const GET_CASE_STATS = gql`
  query caseControlMoistureTechnology(
    $orderBy: cases_order_by!
    $where: cases_bool_exp
  ) {
    cases(order_by: [$orderBy], where: $where) {
      id
      task
      description
      responsible_id
      responsible {
        id
        acronym
      }
      quality_controls {
        id
      }
      checklists {
        id
      }
      builder {
        id
        address
        zip_code
        city
      }
      address
      zip_code
      city
      case_type_id
      case_type {
        name
      }
      case_files {
        id
      }
      case_photos {
        file {
          created
        }
      }
      reminders {
        id
        date
      }
      date_of_inspection
      estimated_time
      other_address
      changed
    }
  }
`;

type CaseInstance = CaseControlMoistureTechnologyQuery["cases"][number];

const Row = React.memo(function Row({
  caseInstance
}: {
  caseInstance: CaseInstance;
}): JSX.Element {
  const openToLong = useMemo(() => {
    return (
      moment(caseInstance.date_of_inspection) <= moment().subtract(7, "days")
    );
  }, [caseInstance.date_of_inspection]);
  const handleCaseClik = useCallback(() => {
    window.open(`${BASE_URL}/cases/${caseInstance.id}`, "blank");
  }, [caseInstance.id]);
  return (
    <TableRow key={caseInstance.id}>
      <TableCell>{caseInstance.builder?.address}</TableCell>
      <TableCell>{caseInstance.case_type?.name}</TableCell>
      <TableCell>{caseInstance.responsible.acronym}</TableCell>
      <TableCell>
        {caseInstance.date_of_inspection ? (
          <FormattedDate value={caseInstance.date_of_inspection} />
        ) : null}
      </TableCell>
      <TableCell style={openToLong ? { backgroundColor: "red" } : undefined}>
        {openToLong ? (
          <FormattedMessage
            defaultMessage="Status"
            id="moisture-technology-table.status"
          />
        ) : null}
      </TableCell>
      <TableCell>
        {caseInstance.quality_controls.length ||
        caseInstance.checklists.length ? (
          <FormattedMessage
            defaultMessage="Ja"
            id="moisture-technology-table.yes"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Nej"
            id="moisture-technology-table.no"
          />
        )}
      </TableCell>

      <TableCell>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleCaseClik}
        >
          Åbn sag
        </Button>
      </TableCell>
    </TableRow>
  );
});

const BUILDING_INSPECTION_NOT_STARTED_ID =
  "c01495b0-869a-41fc-a552-5664460e9bf2";
const BUILDING_INSPECTION_STARTED_ID = "13b75bf9-57c9-42ad-8c6d-f056e8f394e1";

export function MoistureTechnologyCard({
  search = "",
  userId
}: {
  search?: string;
  userId?: string;
}): JSX.Element {
  const [responsible, setResponsible] = useState<User | null>(null);
  const onUserSelected = useCallback((option): void => {
    setResponsible(option);
  }, []);

  const [caseType, setCaseType] = useState<{ value: string } | null>(null);
  const onCaseTypeSelected = useCallback((option): void => {
    setCaseType(option);
  }, []);

  const [
    getData,
    { data, loading }
  ] = useCaseControlMoistureTechnologyLazyQuery();

  useEffect(() => {
    const caseTypeId = caseType?.value;
    const responsibleId = userId || responsible?.id;

    if (!responsibleId) {
      return;
    }
    const where = getCaseWhere(search, {
      _and: caseTypeId
        ? [{ case_type_id: { _eq: caseTypeId } }]
        : [
            { case_type_id: { _neq: BUILDING_INSPECTION_STARTED_ID } },
            { case_type_id: { _neq: BUILDING_INSPECTION_NOT_STARTED_ID } }
          ],
      closed: { _is_null: true },
      responsible: { id: { _eq: responsibleId } }
    });
    getData({
      variables: {
        orderBy: {
          date_of_inspection: Order_By.Desc
        },
        where
      }
    });
  }, [caseType?.value, getData, responsible?.id, search, userId]);

  return (
    <Paper style={{ minHeight: 300, overflowX: "scroll" }}>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Grid container>
          {!userId ? (
            <Grid item lg={6} xs={12}>
              <UserSelect
                field={{ name: "responsible", value: responsible }}
                form={{ errors: {} }}
                onlyGroups={[4, 5]}
                placeholder={
                  <FormattedMessage
                    defaultMessage="Sagsansvarlig"
                    id="building-inspectio.responsible"
                  />
                }
                onSelect={onUserSelected}
              />
            </Grid>
          ) : null}
          <Grid item lg={6} xs={12}>
            <CaseTypeSelect
              field={{ name: "caseType", value: caseType }}
              form={{ errors: {} }}
              placeholder={
                <FormattedMessage
                  defaultMessage="Sagstype"
                  id="moisture-technology.case_type"
                />
              }
              onSelect={onCaseTypeSelected}
            />
          </Grid>
        </Grid>
      </div>
      {loading && !data?.cases ? (
        <div style={{ padding: 10, textAlign: "center" }}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "#F0F0F0" }}>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sagsadresse"
                  id="moisture-technology-table.header.case-address"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sagstype"
                  id="moisture-technology-table.header.case-type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sagsansvarlig"
                  id="moisture-technology-table.header.responsible"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Besigtigelsesdato"
                  id="moisture-technology-table.header.date-of-inspection"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Lukkedato"
                  id="moisture-technology-table.header.closing-date"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Notat på sagen"
                  id="moisture-technology-table.header.notes"
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.cases.map(caseInstance => {
              return <Row key={caseInstance.id} caseInstance={caseInstance} />;
            })}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
}
