import { AutocompleteSelect } from "../../components/autocomplete-select";
import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { CaseTypeSelect } from "../../components/case-type-select";
import { ContactCreateDialog } from "../../components/contact-create-dialog";
import { ContactSelect } from "../../components/contact-select";
import { DateTimePickerField } from "../../components/date-time-fields";
import { Field } from "formik";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { RequestNotes } from "./request-notes";
import { TextFieldAutosize } from "../../components/resizing-text-area";
import { UserSelect } from "../../components/user-select";
import { emailLink, formikPropTypes } from "../../utils/form-helpers";
import { getCaseAddressFormatted } from "../case/case-list";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PropTypes from "prop-types";
import React from "react";
import ReplyIcon from "@material-ui/icons/Reply";
import TextField from "@material-ui/core/TextField";

export const REQUESTS_STATE_OPTIONS = [
  { label: "Ny forespørgsel", value: "new" },
  { label: "Ekspederet", value: "done" },
  { label: "Opfølgning", value: "followup" },
  { label: "Tildelt til ekstern", value: "external" },
  { label: "Sag oprettet", value: "converted" }
];
const messages = defineMessages({
  address: {
    defaultMessage: "Adresse",
    id: "request-form.address"
  },
  builder: {
    defaultMessage: "Bygherre",
    id: "request-form.builder"
  },
  caseType: {
    defaultMessage: "Sagstype",
    id: "request-list.caseType"
  },
  city: {
    defaultMessage: "By",
    id: "request-form.city"
  },
  date: {
    defaultMessage: "Oprettet",
    id: "request-list.date"
  },
  description: {
    defaultMessage: "Beskrivelse",
    id: "request-form.description"
  },
  external: {
    defaultMessage: "Ekstern",
    id: "request-list.external"
  },
  first_name: {
    defaultMessage: "Fornavn",
    id: "request-form.first_name"
  },
  inspectionDate: {
    defaultMessage: "Inspektionsdato",
    id: "request-list.inspectionDate"
  },
  inspectionTime: {
    defaultMessage: "Inspektionstidspunkt",
    id: "request-list.inspectionTime"
  },
  last_name: {
    defaultMessage: "Efternavn",
    id: "request-form.last_name"
  },
  mail: {
    defaultMessage: "Mail",
    id: "request-form.mail"
  },
  mobile: {
    defaultMessage: "Mobil",
    id: "request-form.mobile"
  },
  notes: {
    defaultMessage: "Note",
    id: "request-list.notes"
  },
  phone: {
    defaultMessage: "Telefon",
    id: "request-form.phone"
  },
  responsible: {
    defaultMessage: "Ansvarlig",
    id: "request-list.responsible"
  },
  select: {
    defaultMessage: "Vælg",
    id: "request-list.select"
  },
  state: {
    defaultMessage: "Status",
    id: "request-form.state"
  },
  task: {
    defaultMessage: "Opgave",
    id: "request-form.task"
  },
  zip_code: {
    defaultMessage: "Postnummer",
    id: "request-form.zip_code"
  }
});

const formatAddress = request => {
  return `${request.address}, ${request.zip_code} ${request.city}`;
};

const getReplyMailLink = request => {
  const subject = `Vedr. opgave til Byggesagkyndig.nu - ${request.task}`;
  const recipients = request.mail;
  let { description } = request;
  if (description.length > 300) {
    description = `${description.substr(0, 297)}...`;
  }
  const body = `
Oprindelig forespørgsel:

Opgave:
${request.task}

Bygherre:
${request.builder}

Email:
${request.mail}

Telefon:
${request.mobile}

Addresse:
${formatAddress(request)}
Map: https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    formatAddress(request)
  )}

Beskrivelse:
${description}
`;

  return emailLink(recipients, subject, body);
};

const getExternalMailLink = request => {
  const subject = `Ny opgave fra Byggesagkyndig.nu - ${request.task}`;
  const recipients = request.external.mail;
  let { description } = request;
  if (description.length > 300) {
    description = `${description.substr(0, 297)}...`;
  }
  const body = `
Oprindelig forespørgsel:

Opgave:
${request.case_type?.name}

Bygherre:
${request.builder}

Email:
${request.mail}

Telefon:
${request.mobile}

Addresse:
${formatAddress(request)}
Map: https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    formatAddress(request)
  )}

Beskrivelse:
${description}

General information:
I henhold til aftale, bedes du tage kontakt til dette kundeemne indenfor 16 timer fra modtagelsen af denne mail.

Mvh.
Byggesagkyndig.nu
Mail: info@byggesagkyndig.nu
Tlf.: 70 202 102
`;

  return emailLink(recipients, subject, body);
};

export const ResponsibleInfo = React.memo(({ user }) => (
  <table>
    <tbody style={{ fontSize: 14 }}>
      <tr>
        <td style={{ fontWeight: "bold", height: "2em", width: 80 }}>
          <FormattedMessage defaultMessage="Telefon" id="request-list.phone" />
        </td>
        <td>
          <a href={`tel:${user.phone}`}>{user.phone}</a>
        </td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold", height: "2em" }}>
          <FormattedMessage defaultMessage="Mobil" id="request-list.mobile" />
        </td>
        <td>
          <a href={`tel:${user.mobile}`}>{user.mobile}</a>
        </td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold", height: "2em" }}>
          <FormattedMessage defaultMessage="Mail" id="request-list.mail" />
        </td>
        <td>
          <a href={`mailto:${user.mail}`}>{user.mail}</a>
        </td>
      </tr>
    </tbody>
  </table>
));
ResponsibleInfo.displayName = "ResponsibleInfo";
ResponsibleInfo.propTypes = {
  user: PropTypes.object.isRequired
};

const getFormattedAddress = contact => {
  const { address, city, zip_code } = contact;
  return `${address || ""}, ${zip_code || ""} ${city || ""}`;
};

export const ExternalInfo = React.memo(
  ({ caseInstance, contact, secondaryContact }) => (
    <table>
      <tbody style={{ fontSize: 13 }}>
        <tr>
          <td style={{ fontWeight: "bold", height: "2em", width: 80 }}>
            <FormattedMessage defaultMessage="Navn" id="request-list.name" />
          </td>
          <td>
            {contact.first_name || ""} {contact.last_name || ""}
          </td>
          {secondaryContact ? (
            <td style={{ color: "#999", paddingLeft: 5 }}>
              {secondaryContact.first_name || ""}{" "}
              {secondaryContact.last_name || ""}
            </td>
          ) : null}
        </tr>
        <tr>
          <td style={{ fontWeight: "bold", height: "2em", width: 80 }}>
            <FormattedMessage
              defaultMessage="Firma"
              id="request-list.company"
            />
          </td>
          <td>{contact.company || ""}</td>
          {secondaryContact ? (
            <td style={{ color: "#999", paddingLeft: 5 }}>
              {secondaryContact.company || ""}
            </td>
          ) : null}
        </tr>
        {caseInstance ? (
          <tr>
            <td style={{ fontWeight: "bold", height: "2em", width: 80 }}>
              <FormattedMessage
                defaultMessage="Adresse"
                id="request-list.address"
              />
            </td>
            <td>{getFormattedAddress(contact)}</td>
            {secondaryContact ? (
              <td style={{ color: "#999", paddingLeft: 5 }}>
                {getFormattedAddress(secondaryContact)}
              </td>
            ) : null}
          </tr>
        ) : null}
        <tr>
          <td style={{ fontWeight: "bold", height: "2em", width: 80 }}>
            <FormattedMessage
              defaultMessage="Telefon"
              id="request-list.phone"
            />
          </td>
          <td>
            <a href={`tel:${contact.phone}`}>{contact.phone}</a>
          </td>
          {secondaryContact ? (
            <td style={{ color: "#999", paddingLeft: 5 }}>
              <a href={`tel:${secondaryContact.phone}`}>
                {secondaryContact.phone}
              </a>
            </td>
          ) : null}
        </tr>
        <tr>
          <td style={{ fontWeight: "bold", height: "2em" }}>
            <FormattedMessage defaultMessage="Mobil" id="request-list.mobile" />
          </td>
          <td>
            <a href={`tel:${contact.mobile}`}>{contact.mobile}</a>
          </td>
          {secondaryContact ? (
            <td style={{ color: "#999", paddingLeft: 5 }}>
              <a href={`tel:${secondaryContact.mobile}`}>
                {secondaryContact.mobile}
              </a>
            </td>
          ) : null}
        </tr>
        <tr>
          <td style={{ fontWeight: "bold", height: "2em" }}>
            <FormattedMessage defaultMessage="Mail" id="request-list.mail" />
          </td>
          <td>
            <a href={`mailto:${contact.mail}`}>{contact.mail}</a>
          </td>
          {secondaryContact ? (
            <td style={{ color: "#999", paddingLeft: 5 }}>
              <a href={`mailto:${secondaryContact.mail}`}>
                {secondaryContact.mail}
              </a>
            </td>
          ) : null}
        </tr>
      </tbody>
    </table>
  )
);
ExternalInfo.displayName = "ExternalInfo";
ExternalInfo.propTypes = {
  caseInstance: PropTypes.object,
  contact: PropTypes.object.isRequired
};

const RequestForm = React.memo(
  ({
    contactCreateDialogOpen,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    intl,
    onContactCreated,
    onContactCreateDialogCancel,
    onConvertToCaseClick,
    onCreateContactClick,
    values
  }) => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div>{errors.form}</div>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                error={errors.builder}
                label={intl.formatMessage(messages.builder)}
                name="builder"
                type="text"
                value={values.builder}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field
                component={CaseTypeSelect}
                label={intl.formatMessage(messages.task)}
                name="case_type"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid container item sm={4} xs={12}>
              <div style={{ flexBasis: "80%", flexGrow: "0", maxWidth: "80%" }}>
                <TextField
                  fullWidth
                  error={errors.address}
                  label={intl.formatMessage(messages.address)}
                  name="address"
                  type="text"
                  value={values.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div style={{ flexBasis: "20%", flexGrow: "0", maxWidth: "20%" }}>
                <IconButton
                  component="a"
                  disabled={!values.address}
                  href={`https://www.google.com/maps/search/?api=1&query=${values.address} ${values.zip_code} ${values.city}`}
                  target="_blank"
                >
                  <LocationOnIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                error={errors.zip_code}
                label={intl.formatMessage(messages.zip_code)}
                name="zip_code"
                type="text"
                value={values.zip_code}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                error={errors.city}
                label={intl.formatMessage(messages.city)}
                name="city"
                type="text"
                value={values.city}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid container item sm={4} xs={12}>
              <div style={{ flexBasis: "80%", flexGrow: "0", maxWidth: "80%" }}>
                <TextField
                  fullWidth
                  error={errors.mail}
                  label={intl.formatMessage(messages.mail)}
                  name="mail"
                  type="text"
                  value={values.mail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div style={{ flexBasis: "20%", flexGrow: "0", maxWidth: "20%" }}>
                <IconButton
                  component="a"
                  disabled={!values.mail}
                  href={getReplyMailLink(values)}
                >
                  <ReplyIcon />
                </IconButton>
              </div>
            </Grid>

            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                error={errors.mobile}
                label={intl.formatMessage(messages.mobile)}
                name="mobile"
                type="text"
                value={values.mobile}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                disabled
                fullWidth
                error={errors.date}
                label={intl.formatMessage(messages.date)}
                name="date"
                type="text"
                value={`${intl.formatDate(values.date)} ${intl.formatTime(
                  values.date
                )}`}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldAutosize
                fullWidth
                multiline
                error={errors.description}
                label={intl.formatMessage(messages.description)}
                name="description"
                type="text"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                <FormattedMessage
                  defaultMessage="Booking"
                  id="request-form.booking"
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Field
                component={AutocompleteSelect}
                error={errors.state}
                isClearable={false}
                label={intl.formatMessage(messages.state)}
                name="state"
                options={REQUESTS_STATE_OPTIONS}
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              {(values.state && values.state.value === "new") ||
              values.state.value === "followup" ? (
                <Button
                  color="primary"
                  style={{ marginTop: 20 }}
                  onClick={onConvertToCaseClick}
                >
                  <FormattedMessage
                    defaultMessage="Opret sag"
                    id="request-form.convert"
                  />
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Field
                fullWidth
                component={DateTimePickerField}
                label={intl.formatMessage(messages.inspectionDate)}
                name="inspection_date"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Paper square style={{ padding: 10 }}>
                <Field
                  component={UserSelect}
                  label={intl.formatMessage(messages.responsible)}
                  name="responsible"
                  placeholder={intl.formatMessage(messages.select)}
                />
                {values.responsible ? (
                  <ResponsibleInfo user={values.responsible} />
                ) : null}
              </Paper>
            </Grid>
            <Grid item sm={6} xs={12}>
              {values.state && values.state.value === "external" ? (
                <Paper square style={{ padding: 10 }}>
                  <Grid container>
                    <Grid item xs={11}>
                      <Field
                        component={ContactSelect}
                        label={intl.formatMessage(messages.external)}
                        name="external"
                        placeholder={intl.formatMessage(messages.select)}
                        type="external"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={onCreateContactClick}>
                        <PersonAddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {values.external ? (
                    <>
                      <ExternalInfo contact={values.external} />
                      <Button
                        component="a"
                        disabled={!values.case_type}
                        href={getExternalMailLink(values)}
                      >
                        <MailIcon />
                        <FormattedMessage
                          defaultMessage="Send til ekstern"
                          id="request-from.send-to-external"
                        />
                      </Button>
                    </>
                  ) : null}
                </Paper>
              ) : null}
            </Grid>
          </Grid>
          <Typography variant="h5">
            <FormattedMessage defaultMessage="Noter" id="request-form.notes" />
          </Typography>
          <Field component={RequestNotes} name="request_notes" />
        </form>
        <ContactCreateDialog
          open={contactCreateDialogOpen}
          onCancel={onContactCreateDialogCancel}
          onContactCreated={onContactCreated}
        />
      </>
    );
  }
);
RequestForm.displayName = "RequestForm";
RequestForm.propTypes = {
  ...formikPropTypes
};

const RequestFormWithIntl = injectIntl(RequestForm);

export { RequestFormWithIntl as RequestForm };
