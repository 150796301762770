import { Page } from "../../components/layout/page";
import React, { PureComponent } from "react";

import {
  FormattedDate,
  FormattedTime,
  defineMessages,
  injectIntl
} from "react-intl";
import { MUTable } from "../../components/mu-table";
import { REQUESTS } from "../../graphql/requests";
import { REQUESTS_STATE_OPTIONS } from "./request-form";
import {
  RequestFilterDialog,
  STANDARD_REQUEST_FILTER,
  getFilters,
  setStandardFilter
} from "./request-filter-dialog";
import {
  createWhereObject,
  filterToWhereClause
} from "../../utils/form-helpers";
import { getQueryString } from "../../utils";
import { withApollo } from "@apollo/client/react/hoc";
import Header from "../../components/layout/header";
import _ from "lodash";

const messages = defineMessages({
  address: {
    defaultMessage: "Adresse",
    id: "request-list.address"
  },
  builder: {
    defaultMessage: "Bygherre",
    id: "request-list.builder"
  },
  city: {
    defaultMessage: "By",
    id: "request-list.city"
  },
  date: {
    defaultMessage: "Oprettet",
    id: "request-list.date"
  },
  description: {
    defaultMessage: "Beskrivelse",
    id: "request-list.description"
  },
  first_name: {
    defaultMessage: "Fornavn",
    id: "request-list.first_name"
  },
  last_name: {
    defaultMessage: "Efternavn",
    id: "request-list.last_name"
  },
  mobile: {
    defaultMessage: "Mobil",
    id: "request-list.mobile"
  },
  notChosen: {
    defaultMessage: "Ikke valgt",
    id: "request-list.not-chosen"
  },
  phone: {
    defaultMessage: "Telefon",
    id: "request-list.phone"
  },
  requests: {
    defaultMessage: "Forespørgsler",
    id: "request-list.requests"
  },
  state: {
    defaultMessage: "Status",
    id: "request-list.state"
  },
  task: {
    defaultMessage: "Opgave",
    id: "request-list.task"
  },
  zip_code: {
    defaultMessage: "Postnummer",
    id: "request-list.zip_code"
  }
});

class RequestList extends PureComponent {
  state = {
    filter: filterToWhereClause(getFilters()),
    requestFilterDialogOpen: false,
    search: getQueryString("q") || ""
  };
  handleSearchChange = value => {
    this.setState({ search: value });
  };
  handleRowClick = request => {
    this.props.history.push(`/cases/requests/${request.id}`);
  };
  handleRequestFilterDialogCancel = () => {
    this.setState({ requestFilterDialogOpen: false });
  };
  handleFilterClick = () => {
    this.setState({ requestFilterDialogOpen: true });
  };
  handleFilterChange = filter => {
    this.setState({
      filter: filterToWhereClause(filter),
      requestFilterDialogOpen: false
    });
  };
  handleFilterClearClick = () => {
    setStandardFilter();
    this.handleFilterChange(STANDARD_REQUEST_FILTER);
  };
  render() {
    const { formatMessage } = this.props.intl;
    const filtered = !_.isEqual(getFilters(), STANDARD_REQUEST_FILTER);
    const where = createWhereObject(
      [
        "builder",
        "case_type.name",
        "address",
        "state",
        "city",
        "zip_code",
        "mobile"
      ],
      this.state.search.replace(",", "").replace(".", ""),
      this.state.filter
    );
    return (
      <Page
        appBar={
          <Header
            title={formatMessage(messages.requests)}
            onFilterClearClick={filtered ? this.handleFilterClearClick : null}
            onFilterClick={this.handleFilterClick}
            onSearchChange={this.handleSearchChange}
          />
        }
      >
        <MUTable
          columns={[
            {
              field: "builder",
              title: formatMessage(messages.builder)
            },
            {
              field: "case_type",
              render: request =>
                request.case_type?.name || formatMessage(messages.notChosen),
              title: formatMessage(messages.task)
            },
            {
              field: "address",
              render: address =>
                `${address.address}, ${address.zip_code} ${address.city}`,
              title: formatMessage(messages.address)
            },
            {
              field: "date",
              render: date => (
                <>
                  <FormattedDate value={date.date} />{" "}
                  <FormattedTime value={date.date} />
                </>
              ),
              title: formatMessage(messages.date)
            },
            {
              field: "state",
              render: request => {
                const state = REQUESTS_STATE_OPTIONS.find(
                  option => option.value === request.state
                );
                let initials = "";
                if (request.state === "external") {
                  initials = request.external
                    ? ` (${request.external.mail})`
                    : "";
                } else {
                  initials = request.responsible
                    ? ` (${request.responsible.acronym})`
                    : "";
                }
                return `${state ? state.label : ""}${initials}`;
              },
              title: formatMessage(messages.state)
            }
          ]}
          dataSourceName="requests_aggregate"
          direction="desc"
          gql={REQUESTS}
          limit={500}
          orderBy="date"
          where={where}
          onRowClick={this.handleRowClick}
        />
        <RequestFilterDialog
          filter={this.state.filter}
          open={this.state.requestFilterDialogOpen}
          onCancel={this.handleRequestFilterDialogCancel}
          onFilterChanged={this.handleFilterChange}
        />
      </Page>
    );
  }
}

export default injectIntl(withApollo(RequestList));
