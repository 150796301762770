import gql from "graphql-tag";

export const CASES = gql`
  query list_cases(
    $orderBy: cases_order_by!
    $where: cases_bool_exp
    $offset: Int
    $limit: Int
  ) {
    cases_aggregate(
      offset: $offset
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      aggregate {
        count
      }
      nodes {
        id
        task
        description
        responsible_id
        responsible {
          id
          mail
          acronym
          first_name
          last_name
          phone
          mobile
        }
        builder_id
        builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        secondary_builder_id
        secondary_builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        changed
        closed
        address
        zip_code
        city
        case_type {
          id
          name
        }
        date_of_inspection
        estimated_time
        other_address
        flag
      }
    }
  }
`;

export const CASES_WHERE_closed_OPEN = gql`
  query list_cases_open($orderBy: cases_order_by!, $offset: Int, $limit: Int) {
    cases_aggregate(
      offset: $offset
      order_by: [$orderBy]
      where: { closed: { _eq: true } }
      limit: $limit
    ) {
      aggregate {
        count
      }
      nodes {
        id
        task
        description
        responsible_id
        responsible {
          id
          mail
          acronym
          first_name
          last_name
          phone
          mobile
        }
        builder_id
        builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
          synced
        }
        secondary_builder_id
        secondary_builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        changed
        closed
        address
        zip_code
        city
        case_type {
          id
          name
        }
        date_of_inspection
        estimated_time
        flag
      }
    }
  }
`;

export const UPDATE_CASE = gql`
  mutation update_case($id: uuid, $case: cases_set_input!) {
    update_cases(where: { id: { _eq: $id } }, _set: $case) {
      returning {
        id
        task
        description
        responsible_id
        responsible {
          id
          mail
          acronym
          first_name
          last_name
          phone
          mobile
        }
        builder_id
        builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
          synced
        }
        secondary_builder_id
        secondary_builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        company_id
        company {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        changed
        closed
        address
        zip_code
        city
        case_type {
          id
          name
        }
        date_of_inspection
        estimated_time
        important_note
        lat
        lng
        other_address
        other_note
        toilet
        usefull_note
        flag
        suppliers {
          contact {
            id
            first_name
            last_name
            company
            address
            zip_code
            city
            phone
            mobile
            mail
          }
        }
      }
    }
  }
`;

export const CREATE_CASE = gql`
  mutation insert_case($case: cases_insert_input!) {
    insert_cases(objects: [$case]) {
      returning {
        id
        task
        description
        responsible_id
        responsible {
          id
          mail
          acronym
          first_name
          last_name
          phone
          mobile
        }
        builder_id
        builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
          synced
        }
        secondary_builder_id
        secondary_builder {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        company_id
        company {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        changed
        closed
        address
        zip_code
        city
        flag
        case_type {
          id
          name
        }
        date_of_inspection
        estimated_time
        important_note
        lat
        lng
        other_address
        other_note
        toilet
        usefull_note
        suppliers {
          contact {
            id
            first_name
            last_name
            company
            address
            zip_code
            city
            phone
            mobile
            mail
          }
        }
      }
    }
  }
`;

export const GET_CASE = gql`
  query get_case($caseId: uuid) {
    cases(where: { id: { _eq: $caseId } }) {
      id
      task
      description
      responsible_id
      responsible {
        id
        mail
        acronym
        first_name
        last_name
        phone
        mobile
      }
      builder_id
      builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
        synced
      }
      secondary_builder_id
      flag
      secondary_builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      company_id
      company {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      changed
      closed
      address
      zip_code
      city
      case_type {
        id
        name
      }
      date_of_inspection
      estimated_time
      important_note
      lat
      lng
      other_address
      other_note
      toilet
      usefull_note
      suppliers {
        contact {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
      }
    }
  }
`;

export const CREATE_CASE_SUPPLIER = gql`
  mutation insert_case_supplier(
    $case_suppliers: [case_suppliers_insert_input!]!
  ) {
    insert_case_suppliers(objects: $case_suppliers) {
      returning {
        id
        case_id
        contact_id
      }
    }
  }
`;

export const DELETE_CASE_SUPPLIER = gql`
  mutation delete_case_suppliers($where: case_suppliers_bool_exp!) {
    delete_case_suppliers(where: $where) {
      affected_rows
    }
  }
`;

export const CASE_PHOTOS = gql`
  query list_case_photos(
    $orderBy: case_photos_order_by!
    $where: case_photos_bool_exp
    $limit: Int
  ) {
    case_photos(order_by: [$orderBy], where: $where, limit: $limit) {
      id
      file_id
      case_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
`;

export const CREATE_CASE_PHOTO = gql`
  mutation insert_case_photo($case_photos: [case_photos_insert_input!]!) {
    insert_case_photos(objects: $case_photos) {
      returning {
        id
        file_id
        case_id
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const GET_CASE_PHOTO = gql`
  query get_case_photo($photoId: uuid) {
    case_photos(where: { id: { _eq: $photoId } }) {
      id
      file_id
      case_id
      title
      file {
        id
        filename
        share_key
        created
      }
    }
  }
`;

export const UPDATE_CASE_PHOTO = gql`
  mutation update_case_photo($id: uuid, $case_photo: case_photos_set_input!) {
    update_case_photos(where: { id: { _eq: $id } }, _set: $case_photo) {
      returning {
        id
        file_id
        case_id
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation update_file($id: uuid, $file: files_set_input!) {
    update_files(where: { id: { _eq: $id } }, _set: $file) {
      returning {
        id
        share_key
        filename
        created
      }
    }
  }
`;

export const CASE_FILES = gql`
  query list_case_files(
    $orderBy: case_files_order_by!
    $where: case_files_bool_exp
    $limit: Int
  ) {
    case_files(order_by: [$orderBy], where: $where, limit: $limit) {
      id
      file_id
      case_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
`;

export const CREATE_CASE_FILE = gql`
  mutation insert_case_file($case_files: [case_files_insert_input!]!) {
    insert_case_files(objects: $case_files) {
      returning {
        id
        file_id
        case_id
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const GET_CASE_FILE = gql`
  query get_case_file($fileId: uuid) {
    case_files(where: { id: { _eq: $fileId } }) {
      id
      file_id
      case_id
      title
      file {
        id
        filename
        share_key
        created
      }
    }
  }
`;

export const UPDATE_CASE_FILE = gql`
  mutation update_case_file($id: uuid, $case_file: case_files_set_input!) {
    update_case_files(where: { id: { _eq: $id } }, _set: $case_file) {
      returning {
        id
        file_id
        case_id
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const CASE_DOCUMENTS = gql`
  query list_case_documents($case_id: uuid!) {
    case_doc_notes(where: { case_id: { _eq: $case_id } }) {
      id
      changed
      created
      type
      title
      share_key
      user {
        acronym
      }
    }
    case_doc_order_confirmations(where: { case_id: { _eq: $case_id } }) {
      id
      changed
      created
      type
      title
      share_key
      user {
        acronym
      }
    }
    case_doc_quality_controls(where: { case_id: { _eq: $case_id } }) {
      id
      changed
      created
      type
      title
      share_key
      user {
        acronym
      }
    }
    case_doc_checklists(where: { case_id: { _eq: $case_id } }) {
      id
      changed
      created
      type
      title
      share_key
      user {
        acronym
      }
    }
    case_doc_economy(where: { case_id: { _eq: $case_id } }) {
      id
      changed
      created
      type
      title
      share_key
      user {
        acronym
      }
    }
  }
`;

export const CASES_CORDS = gql`
  query list_cases_cords($where: cases_bool_exp) {
    cases(where: $where) {
      id
      lat
      lng
      case_type_id
    }
  }
`;

export const DELETE_CASE = gql`
  mutation delete_cases($where: cases_bool_exp!) {
    update_cases(where: $where, _set: { deleted: true }) {
      affected_rows
    }
  }
`;
