/* eslint-disable react/jsx-no-bind */
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import { Page } from "../../components/layout/page";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import React, { useCallback, useEffect } from "react";
import gql from "graphql-tag";

export const GET_ABSENCE_TYPE = gql`
  query get_absence_types($id: uuid) {
    absence_types(where: { id: { _eq: $id } }) {
      id
      label
      active
      category
    }
  }
`;

export const CREATE_ABSENCE_TYPE = gql`
  mutation insert_absence_type($absenceType: absence_types_insert_input!) {
    insert_absence_types(objects: [$absenceType]) {
      returning {
        id
        label
        active
        category
      }
    }
  }
`;

export const UPDATE_ABSENCE_TYPE = gql`
  mutation update_absence_type(
    $id: uuid
    $absenceType: absence_types_set_input!
  ) {
    update_absence_types(where: { id: { _eq: $id } }, _set: $absenceType) {
      returning {
        id
        label
        active
        category
      }
    }
  }
`;

export const AbsenceTypeEntry = function AbsenceTypeEntry(): JSX.Element {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const client = useApolloClient();

  const [getData, { data }] = useLazyQuery(GET_ABSENCE_TYPE);

  useEffect(() => {
    getData({
      variables: {
        id
      }
    });
  }, [getData, id]);

  const handleSubmit = useCallback(
    async (
      values: { active: boolean; category: string; label: string },
      { resetForm, setErrors, setSubmitting }
    ) => {
      const { active, category, label } = values;
      const variables = {
        absenceType: {
          active,
          category,
          label,
          type: "day"
        },
        id
      };
      try {
        let response: { data?: any } | null;

        if (id) {
          response = await client.mutate({
            mutation: UPDATE_ABSENCE_TYPE,
            variables
          });
        } else {
          response = await client.mutate({
            mutation: CREATE_ABSENCE_TYPE,
            variables
          });
        }

        if (id) {
          resetForm();
        } else {
          const createdAbsenceTypeId =
            response.data?.insert_absence_types.returning[0].id;
          history.replace(`/settings/absence-types/${createdAbsenceTypeId}`);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const errors = e.graphQLErrors?.map(error => error.message);
        // eslint-disable-next-line no-console
        console.log(errors);
        setSubmitting(false);
        setErrors({ form: errors });
      }
    },
    [client, history, id]
  );
  if (id && !data?.absence_types) {
    return <div>loading</div>;
  }

  return (
    <Formik
      initialValues={
        data?.absence_types
          ? data.absence_types[0]
          : {
              active: true,
              category: "sick",
              label: ""
            }
      }
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        error,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        isSubmitting,
        submitForm,
        values
      }) => {
        const showNavigationWarning = (): boolean => {
          return !isSubmitting && dirty;
        };
        return (
          <Page
            appBar={
              <Header
                dirty={dirty}
                title={
                  <FormattedMessage
                    defaultMessage="Fraværstype"
                    id="absence-type.title"
                  />
                }
                onResetButton={handleReset}
                onSaveButton={submitForm}
              />
            }
          >
            <div
              style={{
                backgroundColor: "#FFF",
                minHeight: "calc(100% - 64px)",
                padding: 20
              }}
            >
              {error ? (
                <>
                  <Typography color="error" variant="subtitle1">
                    <FormattedMessage
                      defaultMessage="Der opstod en fejl, prøv igen senere"
                      id="absence-types.error"
                    />
                  </Typography>
                </>
              ) : null}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    error={!!errors.label}
                    label={
                      <FormattedMessage
                        defaultMessage="Label"
                        id="absence-types.label"
                      />
                    }
                    name="label"
                    type="text"
                    value={values.label}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>
                      <FormattedMessage
                        defaultMessage="Type"
                        id="absence-types.type"
                      />
                    </InputLabel>
                    <Select
                      name="category"
                      value={values.category}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value="sick">
                        <FormattedMessage
                          defaultMessage="Sygdom"
                          id="absence-types.sick"
                        />
                      </MenuItem>

                      <MenuItem value="vacation">
                        <FormattedMessage
                          defaultMessage="Ferie/Fri"
                          id="absence-types.vacation"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.active}
                        name="active"
                        onChange={handleChange}
                      />
                    }
                    label={
                      <FormattedMessage
                        defaultMessage="Aktiv"
                        id="absence-types.active"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <AreYouSure when={showNavigationWarning} />
          </Page>
        );
      }}
    </Formik>
  );
};
