import { FormattedMessage } from "react-intl";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@material-ui/core";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../utils/with-app-context";
import { withRouter } from "react-router";
import Bowser from "bowser";
import PropTypes from "prop-types";
import React, { PureComponent, useCallback } from "react";
import _ from "lodash";
const browser = Bowser.getParser(window.navigator.userAgent);
const isMobile = browser.getPlatformType(true) !== "desktop";

const CELL_STYLE = isMobile ? { paddingRight: 0 } : {};

const TableHeader = React.memo(({ cellStyle, columns }) => (
  <TableHead>
    <TableRow>
      {columns.map((column, index) =>
        column.nextRow ? null : (
          <TableCell
            key={index}
            style={{ color: "#000", fontSize: 14, ...CELL_STYLE, ...cellStyle }}
          >
            {column.sortBy ? (
              <TableSortLabel
                active={column.sortingActive}
                direction={column.sortDirection}
                onClick={column.sortBy}
              >
                {column.title}
              </TableSortLabel>
            ) : (
              column.title
            )}
          </TableCell>
        )
      )}
    </TableRow>
  </TableHead>
));
TableHeader.displayName = "TableHeader";
TableHeader.propTypes = {
  cellStyle: PropTypes.object,
  columns: PropTypes.array.isRequired
};
TableHeader.defaultProps = {
  cellStyle: {}
};

const Row = React.memo(({ children, instance, newRowGroup, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(instance);
  }, [instance, onClick]);
  return (
    <TableRow
      style={{
        borderTop: newRowGroup ? "4px solid #ccc" : "auto",
        cursor: onClick ? "pointer" : "auto"
      }}
      onClick={onClick ? handleClick : undefined}
    >
      {children}
    </TableRow>
  );
});
Row.displayName = "Row";
Row.propTypes = {
  children: PropTypes.array.isRequired,
  instance: PropTypes.object.isRequired,
  newRowGroup: PropTypes.bool,
  onClick: PropTypes.func
};

class MUTable extends PureComponent {
  static propTypes = {
    cellStyle: PropTypes.object,
    client: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    context: PropTypes.object.isRequired,
    customSort: PropTypes.object,
    dataSourceName: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      .isRequired,
    direction: PropTypes.string,
    extraRows: PropTypes.array,
    extraVariables: PropTypes.object,
    gql: PropTypes.object.isRequired,
    limit: PropTypes.number,
    newRowGroup: PropTypes.func,
    noPadding: PropTypes.bool,
    onRowClick: PropTypes.func,
    orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    updateCounter: PropTypes.number,
    where: PropTypes.object
  };

  static defaultProps = {
    direction: "asc",
    extraRows: [],
    limit: 40
  };

  state = {
    data: null,
    error: false
  };
  async componentDidMount() {
    const data = await this.fetchData();
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ data });
    this.props.context.setLoading(false);
  }
  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.where, this.props.where) ||
      !_.isEqual(prevProps.orderBy, this.props.orderBy) ||
      prevProps.updateCounter !== this.props.updateCounter
    ) {
      _.debounce(async () => {
        const data = await this.fetchData();
        this.setState({ data });
        this.props.context.setLoading(false);
      }, 500)();
    }
  }
  fetchData = async () => {
    this.props.context.setLoading(true);
    this.setState({ error: false });

    const {
      client,
      customSort,
      dataSourceName,
      direction,
      extraVariables,
      gql,
      limit,
      orderBy,
      where
    } = this.props;
    let finalOrderBy;
    if (orderBy) {
      if (typeof orderBy === "string") {
        finalOrderBy = { [orderBy]: direction };
      } else {
        finalOrderBy = orderBy;
      }
    }
    try {
      client.stop();
      const response = await client.query({
        fetchPolicy: "network-only",
        query: gql,
        variables: extraVariables || {
          limit,
          orderBy: finalOrderBy,
          where
        }
      });
      if (Array.isArray(dataSourceName)) {
        let instances = [];
        dataSourceName.forEach(name => {
          instances = [...instances, ...response.data[name]];
        });
        instances.sort(customSort);
        return instances;
      }
      return (
        response.data[dataSourceName].nodes || response.data[dataSourceName]
      );
    } catch (e) {
      this.props.context.setLoading(false);
      this.setState({ error: true });
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    }
  };

  render() {
    const { cellStyle, columns, extraRows, noPadding, onRowClick } = this.props;
    const rows = [];
    const rowData = this.state.data ? [...extraRows, ...this.state.data] : [];
    rowData.forEach((instance, rowIndex) => {
      const rowColums = [];
      let extraRow = null;
      const hasExtraRow = columns.some(column => column.nextRow);
      let newRowGroup = false;
      if (this.props.newRowGroup) {
        if (rowIndex > 0) {
          newRowGroup = this.props.newRowGroup(rowData[rowIndex - 1], instance);
        }
      }
      columns.forEach((column, index) => {
        let value;
        if (column.render) {
          value = column.render(instance);
        } else if (column.component) {
          value = React.cloneElement(column.component, { instance });
        } else {
          value = instance[column.field];
        }
        const style = {
          borderBottom: this.props.newRowGroup ? "2px solid #CCC" : "auto",
          cursor: !column.noClick ? "pointer" : "auto",
          ...column.style
        };

        if (column.nextRow) {
          extraRow = (
            <Row
              key={`${instance.id}-extra`}
              instance={instance}
              noPadding={noPadding}
            >
              <TableCell
                key={index}
                colSpan={columns.length}
                style={index === 0 ? { paddingLeft: 20, ...style } : style}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={
                  column.onClick
                    ? () => {
                        column.onClick(instance);
                      }
                    : !column.noClick && onRowClick
                    ? () => {
                        onRowClick(instance);
                      }
                    : undefined
                }
              >
                {value}
              </TableCell>
            </Row>
          );
        } else {
          if (hasExtraRow) {
            style["borderBottom"] = "none";
          }
          rowColums.push(
            <TableCell
              key={index}
              style={index === 0 ? { paddingLeft: 20, ...style } : style}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={
                column.onClick
                  ? () => {
                      column.onClick(instance);
                    }
                  : !column.noClick && onRowClick
                  ? () => {
                      onRowClick(instance);
                    }
                  : undefined
              }
            >
              {value}
            </TableCell>
          );
        }
      });
      rows.push(
        <React.Fragment key={instance.id}>
          <Row
            instance={instance}
            newRowGroup={newRowGroup}
            noPadding={noPadding}
          >
            {rowColums}
          </Row>
          {extraRow}
        </React.Fragment>
      );
    });

    return (
      <div>
        <Table
          size={isMobile ? "small" : "medium"}
          style={{
            backgroundColor: "#FFF",
            width: "100%"
          }}
        >
          <TableHeader cellStyle={cellStyle} columns={columns} />
          <TableBody>{rows}</TableBody>
        </Table>
        {this.state.error ? (
          <Typography align="center" color="error" variant="h4">
            <FormattedMessage
              defaultMessage="Der skete en fejl"
              id="mu-table.error"
            />
          </Typography>
        ) : null}
      </div>
    );
  }
}

const enhanced = withAppContext(withRouter(withApollo(MUTable)));

export { enhanced as MUTable };
