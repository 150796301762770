import { AutocompleteSelect } from "./autocomplete-select";
import {
  ListUsersQuery,
  Order_By,
  useListUsersLazyQuery
} from "../generated/graphql";
import { createWhereObject } from "../utils/form-helpers";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

export type User = ListUsersQuery["users_aggregate"]["nodes"][number];

export const formatUserLabel = (user: User): string =>
  `${user.first_name || ""} ${user.last_name || ""}: ${user.mail || ""}`;

type UserSelectProps = {
  field: any;
  form: any;
  limit?: number;
  onlyGroups?: number[];
  onSelect: any;
  placeholder?: string | JSX.Element;
  SelectProps?: any;
};

export const UserSelect = React.memo(function UserSelect(
  props: UserSelectProps
): JSX.Element {
  const callbackRef = useRef<(data: any) => void>();
  const [getData, { data }] = useListUsersLazyQuery();
  const userData = data?.users_aggregate;

  const baseWhere = useMemo(() => {
    if (props.onlyGroups) {
      return {
        active: { _eq: true },
        employee_number: { _in: props.onlyGroups }
      };
    }
    return { active: { _eq: true } };
  }, [props.onlyGroups]);

  const handleLoadUsers = useCallback(
    (input: string, callback: (data: any) => void) => {
      const where = input
        ? createWhereObject(
            ["first_name", "last_name", "acronym"],
            input,
            baseWhere
          )
        : undefined;
      callbackRef.current = callback;
      getData({
        variables: {
          limit: props.limit || 25,
          orderBy: { first_name: Order_By.Asc },
          where
        }
      });
    },
    [baseWhere, getData, props.limit]
  );

  useEffect(() => {
    if (userData && callbackRef.current) {
      callbackRef.current(
        userData.nodes.map(user => ({
          ...user,
          label: formatUserLabel(user),
          value: user.id
        }))
      );
    }
  }, [userData]);

  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);

  useEffect(() => {
    getData({
      variables: {
        limit: props.limit || 25,
        orderBy: { first_name: Order_By.Asc },
        where: baseWhere
      }
    });
  }, [baseWhere, getData, props.limit]);

  useEffect(() => {
    if (userData && !defaultOptions.length) {
      setDefaultOptions(
        userData.nodes.map(user => ({
          ...user,
          label: formatUserLabel(user),
          value: user.id
        }))
      );
    }
  }, [userData, defaultOptions]);

  const AutocompleteSelectWithoutType: any = AutocompleteSelect;

  return (
    <AutocompleteSelectWithoutType
      defaultOptions={defaultOptions}
      onLoadOptions={handleLoadUsers}
      {...props}
    />
  );
});
