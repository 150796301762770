/* eslint-disable react/jsx-no-bind */
import { AbsenceTypeSelect } from "../../components/absence-type-select";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { DatePickerField } from "../../components/date-time-fields";
import { Field, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { Page } from "../../components/layout/page";
import { UserSelect, formatUserLabel } from "../../components/user-select";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import React, { useCallback, useEffect } from "react";
import gql from "graphql-tag";
import moment from "moment";

export const GET_ABSENCE = gql`
  query get_absence($id: uuid) {
    absence(where: { id: { _eq: $id } }) {
      absence_type_id
      employee_id
      employee {
        id
        mail
        acronym
        first_name
        last_name
      }
      end
      id
      notes
      start
      half_day
    }
  }
`;

export const CREATE_ABSENCE = gql`
  mutation insert_absence($absence: absence_insert_input!) {
    insert_absence(objects: [$absence]) {
      returning {
        absence_type_id
        employee_id
        employee {
          id
          mail
          acronym
          first_name
          last_name
        }
        end
        id
        notes
        start
        half_day
      }
    }
  }
`;

export const UPDATE_ABSENCE = gql`
  mutation update_absence($id: uuid, $absence: absence_set_input!) {
    update_absence(where: { id: { _eq: $id } }, _set: $absence) {
      returning {
        absence_type_id
        employee_id
        employee {
          id
          mail
          acronym
          first_name
          last_name
        }
        end
        id
        notes
        start
        half_day
      }
    }
  }
`;

const DELETE_ABSENCE = gql`
  mutation delete_absence($id: uuid!) {
    delete_absence(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const AbsenceEntry = function AbsenceEntry(): JSX.Element {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const client = useApolloClient();

  const [getData, { data }] = useLazyQuery(GET_ABSENCE);

  useEffect(() => {
    getData({
      variables: {
        id
      }
    });
  }, [getData, id]);

  const handleDelete = useCallback(async () => {
    await client.mutate({
      mutation: DELETE_ABSENCE,
      variables: { id }
    });
    history.goBack();
  }, [client, history, id]);

  const handleSubmit = useCallback(
    async (
      values: {
        absence_type_id: any;
        employee: any;
        end: Date;
        half_day: boolean;
        id: string;
        notes: string;
        start: Date;
      },
      { resetForm, setErrors, setSubmitting }
    ) => {
      const { absence_type_id, employee, end, half_day, notes, start } = values;
      const variables = {
        absence: {
          absence_type_id,
          employee_id: employee?.id,
          end: half_day ? start.toISOString() : end.toISOString(),
          half_day,
          notes,
          start: start.toISOString()
        },
        id
      };
      try {
        let response: { data?: any } | null;

        if (id) {
          response = await client.mutate({
            mutation: UPDATE_ABSENCE,
            variables
          });
        } else {
          response = await client.mutate({
            mutation: CREATE_ABSENCE,
            variables
          });
        }

        if (id) {
          resetForm();
        } else {
          const createdAbsenceId =
            response.data?.insert_absence.returning[0].id;
          history.replace(`/absence/update/${createdAbsenceId}`);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const errors = e.graphQLErrors?.map(error => error.message);
        // eslint-disable-next-line no-console
        console.log(errors);
        setSubmitting(false);
        setErrors({ form: errors });
      }
    },
    [client, history, id]
  );
  if (id && !data?.absence) {
    return <div>loading</div>;
  }
  const existing = data?.absence && data?.absence[0];
  return (
    <Formik
      initialValues={
        existing
          ? {
              ...existing,
              employee: existing.employee
                ? {
                    id: existing.employee_id,
                    label: formatUserLabel(existing.employee)
                  }
                : null,
              end: new Date(existing.end),
              start: new Date(existing.start)
            }
          : {
              absence_type_id: "",
              employee: null,
              end: moment().endOf("days"),
              half_day: false,
              start: moment().startOf("days")
            }
      }
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        error,
        handleChange,
        handleReset,
        isSubmitting,
        submitForm,
        values
      }) => {
        const showNavigationWarning = (): boolean => {
          return !isSubmitting && dirty;
        };
        return (
          <Page
            appBar={
              <Header
                dirty={dirty}
                title={
                  <FormattedMessage
                    defaultMessage="Registrer fravær"
                    id="absence-type.title"
                  />
                }
                onDeleteClick={id ? handleDelete : undefined}
                onResetButton={handleReset}
                onSaveButton={submitForm}
              />
            }
          >
            <div
              style={{
                backgroundColor: "#FFF",
                minHeight: "calc(100% - 64px)",
                padding: 20
              }}
            >
              {error ? (
                <>
                  <Typography color="error" variant="subtitle1">
                    <FormattedMessage
                      defaultMessage="Der opstod en fejl, prøv igen senere"
                      id="calendar-entry-dialog.error"
                    />
                  </Typography>
                </>
              ) : null}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={UserSelect}
                    label={
                      <FormattedMessage
                        defaultMessage="Medarbejder"
                        id="calendar-entry-dialog.employee"
                      />
                    }
                    name="employee"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={AbsenceTypeSelect}
                    label={
                      <FormattedMessage
                        defaultMessage="Type"
                        id="calendar-entry-dialog.type"
                      />
                    }
                    name="absence_type_id"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.half_day}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <FormattedMessage
                        defaultMessage="Halv dag"
                        id="calendar-entry-dialog.half_day"
                      />
                    }
                    name="half_day"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Field
                    fullWidth
                    component={DatePickerField}
                    label={
                      <FormattedMessage
                        defaultMessage="Start"
                        id="order-confirmations-form.start"
                      />
                    }
                    name="start"
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  {!values.half_day ? (
                    <Field
                      fullWidth
                      component={DatePickerField}
                      label={
                        <FormattedMessage
                          defaultMessage="Slut"
                          id="order-confirmations-form.end"
                        />
                      }
                      name="end"
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Grid item>
                  <TextField
                    fullWidth
                    label={
                      <FormattedMessage
                        defaultMessage="Note"
                        id="checklist-items.notes"
                      />
                    }
                    name="notes"
                    type="text"
                    value={values.notes}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            <AreYouSure when={showNavigationWarning} />
          </Page>
        );
      }}
    </Formik>
  );
};
