/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import { APP_BAR_HEIGHT, Page } from "../../components/layout/page";
import { AppBar, Link, Tab, Tabs } from "@material-ui/core";
import { CREATE_CASE } from "../../graphql/cases";
import { CREATE_CASE_DOC_NOTES } from "../../graphql/case-doc-notes";
import {
  CREATE_CONTACT,
  GET_CONTACT_WITH_NAME_MOBILE
} from "../../graphql/contacts";
import {
  CREATE_REQEST_NOTES,
  GET_REQUEST,
  UPDATE_REQUEST
} from "../../graphql/requests";
import { Query } from "@apollo/client/react/components";
import { REQUESTS_STATE_OPTIONS, RequestForm } from "./request-form";
import { ReminderList } from "../../components/reminders/reminder-list";
import { Route, Switch, withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { createClient } from "@google/maps";
import { defineMessages, injectIntl } from "react-intl";
import { formatCaseTypeLabel } from "../../components/case-type-select";
import { formatContactLabel } from "../../components/contact-select";
import { formatUserLabel } from "../../components/user-select";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../utils/with-app-context";
import { withFormik } from "formik";
import { withStyles } from "@material-ui/styles";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import MuFab from "../../components/fab";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import _ from "lodash";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "request-entry.add"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "request-entry.details"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "request-entry.reminders"
  },
  requests: {
    defaultMessage: "Forespørgsler",
    id: "request-entry.requests"
  },
  update: {
    defaultMessage: "Redigér forespørgsel",
    id: "request-entry.update-request"
  }
});

const styles = () => ({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

const schema = yup.object().shape({
  /*first_name: yup.string().required(),
  last_name: yup.string().required(),
  mobile: yup.string().required()*/
});

const generateSelectValue = (value, labelFormatter) => {
  return value
    ? {
        ...value,
        label: labelFormatter(value),
        value: value.id
      }
    : null;
};

class RequestEntry extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    createCase: PropTypes.func.isRequired,
    createCaseNotes: PropTypes.func.isRequired,
    createContact: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    requestId: PropTypes.string.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    updateRequest: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
  };

  state = {
    contactCreateDialogOpen: false,
    googleMapsClient: createClient({
      key: "AIzaSyAareESm47Joh6AkGMIkGBNGRyGVrBeOak",
      Promise
    })
  };

  handleCreateContactClick = () => {
    this.setState({ contactCreateDialogOpen: true });
  };

  handleContactCreateDialogCancel = () => {
    this.setState({ contactCreateDialogOpen: false });
  };
  handleContactCreated = contact => {
    this.props.setFieldValue("external", {
      ...contact,
      label: formatContactLabel(contact),
      value: contact.id
    });
    this.setState({ contactCreateDialogOpen: false });
  };

  handleResetForm = () => this.props.handleReset();

  showNavigationWarning = () => !this.props.isSubmitting && this.props.dirty;

  getGeoCodes = async contact => {
    try {
      const geoCodes = await this.state.googleMapsClient
        .geocode({
          address: `${contact.address} ${contact.zip_code} ${contact.city}`
        })
        .asPromise();
      const { lat, lng } = geoCodes.json.results[0].geometry.location;
      return { lat, lng };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return { lat: undefined, lng: undefined };
  };

  convertToCase = async () => {
    const {
      client,
      context: {
        state: { currentUser }
      },
      createCase,
      createCaseNotes,
      createContact,
      history,
      updateRequest,
      values
    } = this.props;
    const {
      address,
      builder,
      city,
      description,
      id,
      inspection_date,
      mail,
      mobile,
      request_notes,
      task,
      zip_code
    } = values;
    const first_name = builder.substr(0, builder.indexOf(" "));
    const last_name = builder.substr(builder.indexOf(" ") + 1);
    let existingContactCheck;
    try {
      existingContactCheck = await client.query({
        fetchPolicy: "network-only",
        query: GET_CONTACT_WITH_NAME_MOBILE,
        variables: {
          first_name,
          last_name,
          mobile
        }
      });
    } catch (e) {
      console.log("Do something with this error", e);
    }
    let contact;
    if (existingContactCheck.data.contacts.length) {
      contact = existingContactCheck.data.contacts[0];
    } else {
      const contactVariables = {
        contact: {
          address,
          city,
          first_name,
          last_name,
          mail,
          mobile,
          phone: mobile,
          type: "customer",
          zip_code
        }
      };

      try {
        const response = await createContact({
          variables: contactVariables
        });
        contact = response.data.insert_contacts.returning[0];
      } catch (e) {
        console.log("Do something with this error", e);
      }
    }

    const { lat, lng } = await this.getGeoCodes(contact);

    const variables = {
      case: {
        builder_id: contact.id,
        case_type_id: _.get(values, "case_type.id", null),
        created: new Date(),
        created_by_id: currentUser.id,
        date_of_inspection: inspection_date,
        description,
        lat,
        lng,
        responsible_id: _.get(values, "responsible.id", null),
        task
      }
    };

    try {
      const response = await createCase({
        variables
      });
      const createdCaseId = response.data.insert_cases.returning[0].id;
      if (request_notes.length) {
        await createCaseNotes({
          variables: {
            case_doc_notes: request_notes.map(note => {
              let noteToSave = note.notes;
              let noteDescription;
              if (noteToSave.length > 300) {
                noteToSave = note.notes.substring(0, 299);
                noteDescription = note.notes.substring(300);
              }

              return {
                case_id: createdCaseId,
                changed: note.added_date,
                created: note.added_date,
                document_date: note.added_date,
                text: noteDescription,
                title: noteToSave,
                type: "note",
                user_id: note.user.id
              };
            })
          }
        });
      }

      const requestVariables = {
        id,
        request: {
          case_id: createdCaseId,
          state: "converted"
        }
      };
      await updateRequest({
        variables: requestVariables
      });
      this.props.context.setLoading(false);

      history.replace(`/cases/${createdCaseId}`);
    } catch (e) {
      console.log("Do something with this error", e);
    }
    this.props.context.setLoading(false);
  };

  handleConvertToCaseClick = () => {
    this.props.context.setLoading(true);
    if (this.props.dirty) {
      this.props.submitForm().then(() => {
        this.convertToCase();
      });
    } else {
      this.convertToCase();
    }
  };

  handleFabClick = () => {
    this.props.history.push(
      `/cases/requests/${this.props.requestId}/reminders/create`
    );
  };

  render() {
    const {
      classes,
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      requestId,
      submitForm,
      touched,
      values
    } = this.props;
    const currentTab =
      window.location.hash.indexOf("reminders") > -1 ? "reminders" : "details";
    return (
      <Page
        withTabs
        appBar={
          <>
            <AppBar className={classes.appBar} elevation={0} position="fixed">
              <Tabs
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                onChange={this.handleTabChange}
              >
                <Tab
                  component={Link}
                  href={`#/cases/requests/${requestId}`}
                  label={formatMessage(messages.details)}
                  value="details"
                />
                <Tab
                  component={Link}
                  href={`#/cases/requests/${requestId}/reminders`}
                  label={formatMessage(messages.reminders)}
                  value="reminders"
                />
              </Tabs>
            </AppBar>
            <Header
              dirty={dirty}
              title={formatMessage(messages.update)}
              onResetButton={this.handleResetForm}
              onSaveButton={submitForm}
            />
          </>
        }
      >
        <Switch>
          <Route
            path="/cases/requests/:requestId/reminders"
            render={props => (
              <ReminderList
                {...props}
                baseURL={`/cases/requests/${requestId}/reminders`}
                parentId={requestId}
                parentName="request_id"
              />
            )}
          />
          <Route
            path="/cases/requests/:requestId"
            render={props => (
              <div
                style={{
                  backgroundColor: "#FFF",
                  minHeight: "calc(100% - 64px)",
                  padding: 20
                }}
              >
                <RequestForm
                  {...props}
                  contactCreateDialogOpen={this.state.contactCreateDialogOpen}
                  dirty={dirty}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  onContactCreated={this.handleContactCreated}
                  onContactCreateDialogCancel={
                    this.handleContactCreateDialogCancel
                  }
                  onConvertToCaseClick={this.handleConvertToCaseClick}
                  onCreateContactClick={this.handleCreateContactClick}
                />
                <MuFab icon={<AlarmAddIcon />} onClick={this.handleFabClick} />
              </div>
            )}
          />
        </Switch>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const {
    address,
    builder,
    city,
    date,
    description,
    id,
    inspection_date,
    mail,
    mobile,
    request_notes,
    state,
    task,
    zip_code
  } = payload;
  const variables = {
    id,
    request: {
      address,
      builder,
      case_type_id: _.get(payload, "case_type.id", null),
      city,
      date,
      description,
      external_id: _.get(payload, "external.id", null),
      id,
      inspection_date,
      mail,
      mobile,
      responsible_id: _.get(payload, "responsible.id", null),
      state: state ? state.value : undefined,
      task,
      zip_code
    }
  };
  const newRequestNotes = request_notes.filter(notes => !notes.id);
  const requestNotesToBeAdded = newRequestNotes.map(requestNote => {
    const { added_date, notes, user } = requestNote;
    return { added_date, notes, request_id: id, user_id: user.id };
  });
  try {
    if (requestNotesToBeAdded.length) {
      await props.createRequestNotes({
        variables: { request_notes: requestNotesToBeAdded }
      });
    }
    const response = await props.updateRequest({
      variables
    });
    //props.setVariables(response.data.update_requests.returning[0]);
    resetForm();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const EnhancedRequestForm = compose(
  withApollo,
  graphql(UPDATE_REQUEST, { name: "updateRequest" }),
  graphql(CREATE_REQEST_NOTES, { name: "createRequestNotes" }),
  graphql(CREATE_CASE, { name: "createCase" }),
  graphql(CREATE_CASE_DOC_NOTES, {
    name: "createCaseNotes"
  }),
  graphql(CREATE_CONTACT, {
    name: "createContact"
  }),
  withFormik({
    displayName: "RequestEntry",
    handleSubmit,
    mapPropsToValues: ({ variables }) => {
      const responsible = generateSelectValue(
        variables.responsible,
        formatUserLabel
      );
      const external = generateSelectValue(
        variables.external,
        formatContactLabel
      );
      const case_type = generateSelectValue(
        variables.case_type,
        formatCaseTypeLabel
      );
      const state = REQUESTS_STATE_OPTIONS.find(
        option => option.value === variables.state
      );
      const inspection_date = variables.inspection_date || null;
      return {
        ...variables,
        case_type,
        external,
        inspection_date,
        responsible,
        state
      };
    },
    validationSchema: schema
  }),
  withAppContext,
  withRouter,
  injectIntl,
  withStyles(styles)
)(RequestEntry);

const RequestEntryWrapper = ({
  match: {
    params: { requestId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <EnhancedRequestForm
        requestId={requestId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  return (
    <Query query={GET_REQUEST} variables={{ requestId }}>
      {({ data, error, loading }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (error) {
          return <p>Error :(</p>;
        }

        return (
          <EnhancedRequestForm
            requestId={requestId}
            setVariables={setVariables}
            variables={data.requests[0]}
          />
        );
      }}
    </Query>
  );
};

const RequestEntryWrapperWithRouter = withRouter(RequestEntryWrapper);

export { RequestEntryWrapperWithRouter as RequestEntry };
