/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import { APP_BAR_HEIGHT, Page } from "../../components/layout/page";
import {
  CREATE_CASE,
  CREATE_CASE_SUPPLIER,
  DELETE_CASE,
  DELETE_CASE_SUPPLIER,
  GET_CASE,
  UPDATE_CASE
} from "../../graphql/cases";
import { CaseForm } from "./case-form";
import { Query } from "@apollo/client/react/components";
import { flowRight as compose } from "lodash";
import { graphql, withApollo } from "@apollo/client/react/hoc";
//import {CaseReminders} from "./case-reminders";
import { AppBar, Link, Tab, Tabs, withStyles } from "@material-ui/core";
import { CaseDocsList } from "./docs/list";
import { CaseFileList } from "./files/list";
import { CasePhotoList } from "./photos/list";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { ReminderList } from "../../components/reminders/reminder-list";
import { Route, Switch, withRouter } from "react-router-dom";
import { TimeList } from "./time/list";
import { createClient } from "@google/maps";
import { formatCaseTypeLabel } from "../../components/case-type-select";
import { formatContactLabel } from "../../components/contact-select";
import { formatUserLabel } from "../../components/user-select";
import { getCaseAddressFormatted } from "./case-list";
import { withAppContext } from "../../utils/with-app-context";
import { withFormik } from "formik";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import _ from "lodash";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "case-entry.add"
  },
  cases: {
    defaultMessage: "Sager",
    id: "case-entry.cases"
  },
  createNew: {
    defaultMessage: "Opret sag",
    id: "case-entry.create-case"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "case-entry.details"
  },
  documents: {
    defaultMessage: "Dokumenter",
    id: "case-entry.documents"
  },
  files: {
    defaultMessage: "Filarkiv",
    id: "case-entry.files"
  },
  photos: {
    defaultMessage: "Fotoarkiv",
    id: "case-entry.photos"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "case-entry.reminders"
  }
});

const styles = () => ({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

const schema = yup.object().shape({
  builder: yup.string().required(),
  case_type: yup.string().required(),
  responsible: yup.string().required(),
  task: yup.string().required()
});

const generateSelectValue = (value, labelFormatter) => {
  return value
    ? {
        ...value,
        label: labelFormatter(value),
        value: value.id
      }
    : null;
};

class CaseEntry extends PureComponent {
  static propTypes = {
    caseId: PropTypes.string,
    classes: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  state = {
    contactCreateDialogOpen: null,
    googleMapsClient: createClient({
      key: "AIzaSyAareESm47Joh6AkGMIkGBNGRyGVrBeOak",
      Promise
    })
  };

  handleCreateContactClick = () => {
    this.setState({ contactCreateDialogOpen: "builder" });
  };

  handleCreateSecondaryContactClick = () => {
    this.setState({ contactCreateDialogOpen: "secondary_builder" });
  };

  handleCreateCompanyClick = () => {
    this.setState({ contactCreateDialogOpen: "company" });
  };

  handleContactCreateDialogCancel = () => {
    this.setState({ contactCreateDialogOpen: null });
  };

  handleContactCreated = contact => {
    if (this.state.contactCreateDialogOpen === "company") {
      this.props.setFieldValue("suppliers", [
        ...this.props.values.suppliers,
        {
          ...contact,
          label: formatContactLabel(contact),
          value: contact.id
        }
      ]);
    } else {
      this.props.setFieldValue(this.state.contactCreateDialogOpen, {
        ...contact,
        label: formatContactLabel(contact),
        value: contact.id
      });
    }
    if (this.state.contactCreateDialogOpen === "builder") {
      this.handleContactSelected(contact, true);
    }
    this.setState({ contactCreateDialogOpen: null });
  };

  handleContactSelected = (contact, override) => {
    if (override || !this.props.values.other_address) {
      this.setGeoCodes(contact);
    }
  };

  setGeoCodes = async contact => {
    try {
      const geoCodes = await this.state.googleMapsClient
        .geocode({
          address: `${contact.address} ${contact.zip_code} ${contact.city}`
        })
        .asPromise();
      const { lat, lng } = geoCodes.json.results[0].geometry.location;
      this.props.setFieldValue("lat", lat);
      this.props.setFieldValue("lng", lng);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  handleResetForm = () => this.props.handleReset();

  showNavigationWarning = () => !this.props.isSubmitting && this.props.dirty;

  handleDeleteClick = async () => {
    await this.props.client.mutate({
      mutation: DELETE_CASE,
      variables: {
        where: { id: { _eq: this.props.caseId } }
      }
    });
    this.props.history.replace(`/cases/list/current`);
  };

  render() {
    const {
      caseId,
      classes,
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      setFieldValue,
      submitForm,
      touched,
      values
    } = this.props;
    let currentTab = "details";
    if (window.location.hash.indexOf("/reminders") > -1) {
      currentTab = "reminders";
    } else if (window.location.hash.indexOf("/photos") > -1) {
      currentTab = "photos";
    } else if (window.location.hash.indexOf("/files") > -1) {
      currentTab = "files";
    } else if (window.location.hash.indexOf("/documents") > -1) {
      currentTab = "documents";
    } else if (window.location.hash.indexOf("/time") > -1) {
      currentTab = "time";
    }

    const {
      state: { currentUser }
    } = this.props.context;
    const currentRole = currentUser ? currentUser.role : null;
    const isAdmin = currentRole === "admin";
    const isSuperUser = currentRole === "superuser";

    let heading;
    if (caseId) {
      heading = `${getCaseAddressFormatted(values)} - ${values.task}`;
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onDeleteClick={
              this.props.caseId && isAdmin ? this.handleDeleteClick : null
            }
            onResetButton={this.handleResetForm}
            onSaveButton={submitForm}
          />
        }
      >
        <AppBar elevation={1} position="static">
          <Tabs
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            onChange={this.handleTabChange}
          >
            <Tab
              component={Link}
              href={caseId ? `#/cases/${caseId}/` : "#/cases/create"}
              label={formatMessage(messages.details)}
              value="details"
            />
            <Tab
              component={Link}
              disabled={!caseId}
              href={`#/cases/${caseId}/photos`}
              label={formatMessage(messages.photos)}
              value="photos"
            />
            <Tab
              component={Link}
              disabled={!caseId}
              href={`#/cases/${caseId}/documents`}
              label={formatMessage(messages.documents)}
              value="documents"
            />
            <Tab
              component={Link}
              disabled={!caseId}
              href={`#/cases/${caseId}/files`}
              label={formatMessage(messages.files)}
              value="files"
            />
            <Tab
              component={Link}
              disabled={!caseId}
              href={`#/cases/${caseId}/reminders`}
              label={formatMessage(messages.reminders)}
              value="reminders"
            />
            <Tab
              component={Link}
              disabled={!caseId}
              href={`#/cases/${caseId}/time`}
              label={
                <FormattedMessage
                  defaultMessage="Tidsregistrering"
                  id="case-entry.time-tracking"
                />
              }
              value="time"
            />
          </Tabs>
        </AppBar>
        <Switch>
          <Route
            exact
            path="/cases/:caseId"
            render={props => (
              <div
                style={{
                  backgroundColor: "#FFF",
                  minHeight: "calc(100% - 64px)",
                  padding: 10
                }}
              >
                <CaseForm
                  {...props}
                  contactCreateDialogOpen={this.state.contactCreateDialogOpen}
                  currentUser={currentUser}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  onContactCreated={this.handleContactCreated}
                  onContactCreateDialogCancel={
                    this.handleContactCreateDialogCancel
                  }
                  onContactSelected={this.handleContactSelected}
                  onCreateCompanyClick={this.handleCreateCompanyClick}
                  onCreateContactClick={this.handleCreateContactClick}
                  onCreateSecondaryContactClick={
                    this.handleCreateSecondaryContactClick
                  }
                />
              </div>
            )}
          />
          <Route
            exact
            path="/cases/:caseId/reminders"
            render={props => (
              <ReminderList
                {...props}
                baseURL={`/cases/${caseId}/reminders`}
                parentId={caseId}
                parentName="case_id"
              />
            )}
          />
          <Route
            exact
            path="/cases/:caseId/documents"
            render={props => <CaseDocsList {...props} caseInstance={values} />}
          />
          <Route exact component={CasePhotoList} path="/cases/:caseId/photos" />
          <Route
            exact
            path="/cases/:caseId/files"
            render={props => <CaseFileList {...props} caseInstance={values} />}
          />
          <Route
            exact
            path="/cases/:caseId/time"
            render={props => <TimeList {...props} caseInstance={values} />}
          />
        </Switch>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const many2manyDiff = (
  oldList,
  newList,
  ownerID,
  instanceIdFieldName,
  relatedIdFieldName
) => {
  const toDelete = oldList.reduce((removedInstances, oldInstance) => {
    if (!newList.find(instance => instance.id === oldInstance.id)) {
      removedInstances.push({
        _and: [
          { [relatedIdFieldName]: { _eq: oldInstance.id } },
          { [instanceIdFieldName]: { _eq: ownerID } }
        ]
      });
    }
    return removedInstances;
  }, []);

  const toAdd = newList.reduce((addedInstances, instance) => {
    if (!oldList.find(oldInstance => instance.id === oldInstance.id)) {
      addedInstances.push({
        [instanceIdFieldName]: ownerID,
        [relatedIdFieldName]: instance.id
      });
    }
    return addedInstances;
  }, []);
  return { toAdd, toDelete };
};

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  console.log(props);
  const {
    state: { currentUser }
  } = props.context;
  const {
    address,
    city,
    closed,
    date_of_inspection,
    description,
    estimated_time,
    flag,
    important_note,
    lat,
    lng,
    other_address,
    other_note,
    state,
    suppliers,
    task,
    toilet,
    usefull_note,
    zip_code
  } = payload;
  const variables = {
    case: {
      address,
      builder_id: _.get(payload, "builder.id", null),
      case_type_id: _.get(payload, "case_type.id", null),
      city,
      closed:
        !state && !closed ? new Date().toISOString() : state ? null : closed,
      company_id: _.get(payload, "company.id", null),
      date_of_inspection,
      description,
      estimated_time,
      flag,
      important_note,
      lat,
      lng,
      other_address,
      other_note,
      responsible_id: _.get(payload, "responsible.id", null),
      secondary_builder_id: _.get(payload, "secondary_builder.id", null),
      task,
      toilet,
      usefull_note,
      zip_code
    }
  };
  const {
    caseId,
    createCase,
    createCaseSupplier,
    deleteCaseSupplier,
    history,
    updateCase
  } = props;
  try {
    if (caseId) {
      variables.id = caseId;
      const newSuppliers = suppliers || [];

      const oldSuppliers = props.variables.suppliers
        ? props.variables.suppliers.map(supplier => supplier.contact)
        : [];

      const { toAdd, toDelete } = many2manyDiff(
        oldSuppliers,
        newSuppliers,
        caseId,
        "case_id",
        "contact_id"
      );

      if (toDelete.length) {
        await deleteCaseSupplier({
          variables: {
            where: {
              _or: toDelete
            }
          }
        });
      }
      if (toAdd.length) {
        await createCaseSupplier({
          variables: { case_suppliers: toAdd }
        });
      }
      const response = await updateCase({
        variables
      });

      //props.setVariables(response.data.update_cases.returning[0]);
      resetForm();
    } else {
      variables.case.created_by_id = currentUser.id;
      variables.case.created = new Date();

      const response = await createCase({
        variables
      });
      const createdCaseId = response.data.insert_cases.returning[0].id;
      const newSuppliers = suppliers || [];
      const caseSuppliers = newSuppliers.map(supplier => ({
        case_id: createdCaseId,
        contact_id: supplier.contact ? supplier.contact.id : supplier.id
      }));

      await createCaseSupplier({
        variables: { case_suppliers: caseSuppliers }
      });
      history.replace(`/cases/${createdCaseId}`);
    }

    /*p;*/
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const EnhancedCaseForm = compose(
  withApollo,
  withRouter,
  graphql(UPDATE_CASE, { name: "updateCase" }),
  graphql(CREATE_CASE, { name: "createCase" }),
  graphql(CREATE_CASE_SUPPLIER, { name: "createCaseSupplier" }),
  graphql(DELETE_CASE_SUPPLIER, { name: "deleteCaseSupplier" }),
  withAppContext,
  withFormik({
    displayName: "CaseEntry",
    handleSubmit,
    mapPropsToValues: ({ caseId, variables }) => {
      if (caseId && caseId !== "create") {
        const responsible = generateSelectValue(
          variables.responsible,
          formatUserLabel
        );
        const builder = generateSelectValue(
          variables.builder,
          formatContactLabel
        );
        const secondary_builder = generateSelectValue(
          variables.secondary_builder,
          formatContactLabel
        );
        const case_type = generateSelectValue(
          variables.case_type,
          formatCaseTypeLabel
        );
        const company = generateSelectValue(
          variables.company,
          formatContactLabel
        );
        const suppliers = caseId
          ? variables.suppliers.map(supplier =>
              generateSelectValue(supplier.contact, formatContactLabel)
            )
          : [];
        console.log(variables.closed, !variables.closed);
        return {
          ...variables,
          builder,
          case_type,
          company,
          responsible,
          secondary_builder,
          state: !variables.closed,
          suppliers
        };
      }
      return {
        ...variables,
        date_of_inspection: null
      };
    },
    validationSchema: schema
  }),
  injectIntl,
  withStyles(styles)
)(CaseEntry);

const CaseEntryWrapper = ({
  match: {
    params: { caseId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <EnhancedCaseForm
        caseId={caseId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (!caseId) {
    return (
      <EnhancedCaseForm
        setVariables={setVariables}
        variables={{ state: true }}
      />
    );
  }
  return (
    <Query query={GET_CASE} variables={{ caseId }}>
      {({ data, error, loading }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (error) {
          return <p>Error :(</p>;
        }

        return (
          <EnhancedCaseForm
            caseId={caseId}
            setVariables={setVariables}
            variables={data.cases[0]}
          />
        );
      }}
    </Query>
  );
};

const CaseEntryWrapperWithRouter = withRouter(CaseEntryWrapper);

export { CaseEntryWrapperWithRouter as CaseEntry };
